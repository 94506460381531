const data = [
  {
    idx: "1",
    title: "Epic Project",
    categories: ["Mechanical", "Electrical", "water", "perp"],
    image:
      "https://api.pitsasinsurances.com/dl/images/PITSAS%20ARTICLE%20-%20CONSTRUCTION%20ALL%20RISK%20INSURANCE.jpg",
    donor: "United Emirates Embassy",
    date: "12/8/2022",
    price: "544,000",
    details:
      "This is a sample paragraph for the project details \n We here are trying to write as much text as possible",
  },
  {
    idx: "2",
    title: "Epic Project",
    categories: ["Mechanical", "Electrical", "water", "perp"],
    image:
      "https://api.pitsasinsurances.com/dl/images/PITSAS%20ARTICLE%20-%20CONSTRUCTION%20ALL%20RISK%20INSURANCE.jpg",
    donor: "United Emirates Embassy",
    date: "12/8/2022",
    price: "544,000",
    details:
      "This is a sample paragraph for the project details \n We here are trying to write as much text as possible",
  },
];

export default data;
