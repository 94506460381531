import React, { useState, useEffect, Suspense } from "react";
// import ProjectCard from "../../Components/ProjectCard/ProjectCard";
import ProjectCardList from "../../Containers/ProjectCardsList/ProjectCardList";
import "./Projects.scss";
import axios from "axios";
import ReactLoading from "react-loading";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

// import Header from ""
import data from "./DummyList";

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 170,
    // editable: true,
  },
  {
    field: "category",
    headerName: "Categories",
    width: 300,
    // editable: true,
  },
  {
    field: "donor",
    headerName: "Donor",
    width: 150,
    // editable: true,
  },
  {
    field: `date`,
    headerName: "Date",
    width: 150,
    // editable: true,
  },
  {
    field: "price",
    headerName: "Price",
    width: 150,
    // editable: true,
  },
  // {
  //   field: "description",
  //   headerName: "Description",
  //   width: 500,
  //   editable: true,
  // },
  // {
  //   field: "age",
  //   headerName: "Age",
  //   type: "number",
  //   width: 110,
  //   editable: true,
  // },
];

const Projects = () => {
  const [temp, setTemp] = useState(true);
  const [projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get("https://kssm.herokuapp.com/projects/").then((res) => {
      setTemp(!temp);
      setProjects(res.data);

      console.log(res.data);
      setLoading(false);
    });

    console.log(projects);
  }, []);
  return (
    <div className="projects-page">
      <div className="header">
        <h1 className="header-text">Projects</h1>
      </div>

      {loading && (
        <div className="loading">
          <ReactLoading type="bubbles" color="#B89432" />
          <p className="body-text">Loading</p>
        </div>
      )}

      <Suspense>
        <ProjectCardList data={projects} />
      </Suspense>

      <br />
      <br />
      <br />
      {projects && (
        <Box sx={{ height: 400, width: "85%", margin: "auto" }}>
          <DataGrid
            rows={projects}
            columns={columns}
            pageSize={5}
            // rowsPerPageOptions={[10]}
            // checkboxSelection
            // disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: false }}
            getRowId={(row) => row._id}
          />
        </Box>
      )}
    </div>
  );
};

export default Projects;
